import { Testimonial } from "./listing";

const TestimonialComponent = ({
  testimonial,
}: {
  testimonial: Testimonial;
}) => {
  return (
    <div className="flex items-center justify-center">
      {testimonial.image && <div className="w-1/6">{testimonial.image}</div>}
      <div className="mr-4 w-5/6">
        <p className="text-xl mb-8">{testimonial.text}</p>
        {testimonial.name && (
          <p className="text-sm font-semibold">{testimonial.name}</p>
        )}
        {testimonial.designation && (
          <p className="text-sm">{testimonial.designation}</p>
        )}
      </div>
    </div>
  );
};

export default TestimonialComponent;
