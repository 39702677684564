import Link from "next/link";

import CTAButton from "elements/CTA-Button";

import useCases from "./listing";
import UseCase from "./Use-Case";

function UseCases() {
  return (
    <section className="relative">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-brand-900 pointer-events-none"
        aria-hidden="true"
      />
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2" />
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Daisho in the Wild</h2>
            <p className="text-xl text-gray-600">
              Some examples of how our users utilize their super-powers
            </p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
            {useCases.map((c, i) => (
              <UseCase key={c.heading} useCase={c} delay={(i + 1) * 50} />
            ))}
          </div>

          <div
            className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
            data-aos="zoom-y-out"
            data-aos-delay="400"
          >
            <div className="pt-4 mt-4">
              <Link href="/use-cases" passHref>
                <a className="btn text-white bg-gray-900 hover:bg-gray-800 w-full mb-4 sm:w-auto sm:mb-0 mr-4 px-5 py-3 rounded">
                  Explore EVERYTHING Daisho can do
                </a>
              </Link>
              <CTAButton color="secondary" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UseCases;
