import { useState } from "react";

import Transition from "components/Transition";

import features from "./features";
// import dsFeatures from "./ds-features";
// import opFeatures from "./op-features";

const SuperPowers = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <section className="relative" id="super-powers">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className="absolute inset-0 bg-gray-100 pointer-events-none pb-16"
        aria-hidden="true"
      ></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20 pb-12 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="h2 mb-4 text-brand-600">The Super Powers</h2>
            <p className="text-xl text-gray-600">
              Everything you need to generate insights from your data
              super-quickly.
            </p>
          </div>
          {/* Tabs */}
          <div className="flex max-w-lg mb-10 mx-auto text-center border-b-2">
            {features.map((f, i) => (
              <div
                key={i}
                className={`w-1/3 pb-2 ${
                  i === tabIndex ? "border-b-4 border-brand-600" : ""
                }`}
              >
                <button onClick={() => setTabIndex(i)}>{f.heading}</button>
              </div>
            ))}
          </div>
          <div data-aos="fade-right">
            <Transition
              show
              appear
              className="w-full"
              enter="transition ease-in-out duration-700 transform order-first"
              enterStart="opacity-0 translate-y-16"
              enterEnd="opacity-100 translate-y-0"
              leave="transition ease-in-out duration-300 transform absolute"
              leaveStart="opacity-100 translate-y-0"
              leaveEnd="opacity-0 -translate-y-16"
            >
              <div>{features[tabIndex].text}</div>
              <div className="mt-4 pt-4">{features[tabIndex].content}</div>
            </Transition>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuperPowers;
