import { Carousel } from "react-responsive-carousel";

import listing from "./listing";
import Testimonial from "./Testimonial";

const Testimonials = () => {
  return (
    <section className="relative">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className="absolute inset-0 bg-gray-100 pointer-events-none pb-16"
        aria-hidden="true"
      ></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20 pb-12 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="h2 mb-4 text-brand-600">What our SuperHeroes say</h2>
            <p className="text-xl text-gray-600">
              Trusted by Companies of all Shapes and Sizes
            </p>
          </div>

          {/* Section Content */}

          <Carousel
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            showIndicators={true}
            className="max-w-6xl mx-auto"
            autoPlay={true}
            infiniteLoop={true}
            interval={10000}
          >
            {listing.map((t, i) => (
              <div key={i} className="bg-white h-full pt-8 px-4">
                <Testimonial testimonial={t} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
