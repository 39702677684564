import { UseCase } from "./types";

interface Props {
  useCase: UseCase;
  delay?: number;
}

const UseCaseComponent = ({ useCase, delay }: Props) => {
  return (
    <div
      className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl"
      data-aos="zoom-in"
      data-aos-delay={delay}
    >
      {useCase.icon}
      <h4 className="text-xl text-center font-bold leading-snug tracking-tight mb-1">
        {useCase.heading}
      </h4>
      <p className="text-gray-600 text-center">
        {useCase.text}{" "}
        {useCase.highlight && (
          <span className="font-bold italic">{useCase.highlight}</span>
        )}
      </p>
    </div>
  );
};

export default UseCaseComponent;
