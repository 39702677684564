import { useState } from "react";

import Modal from "components/Modal";
import CTAButton from "elements/CTA-Button";

const HowItWorks = () => {
  const [showDemo, setShowDemo] = useState(false);

  return (
    <section className="relative">
      <div
        className="absolute inset-0 pointer-events-none pb-16 bg-purple-100"
        aria-hidden="true"
      />
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 px-4 md:py-20 text-center">
          <h2 className="h2 mb-4 text-brand-600">How Daisho Works</h2>
          <p className="text-xl text-gray-600">
            Or, how to become a SuperHero in THREE simple steps
          </p>
          <div className="flex flex-wrap -mx-8 mt-12 mb-2 sm:px-2 sm:mx-2 md:px-0 md:mx-0">
            <div
              className="lg:w-1/3 px-8 py-8 mb-8 bg-white"
              data-aos="zoom-in"
              data-aos-delay={50}
            >
              <img className="w-1/2 mx-auto mb-8" src="/choose.svg" alt="" />
              <h3 className="text-2xl mb-4 h3">
                <span className="inline-flex items-center justify-center h-12 w-12 mr-2 border rounded-full">
                  1
                </span>
                <span>Select the Problem. And Data.</span>
              </h3>
              <p className="text-gray-500">
                Look through the Daisho catalog to see what's the closest to
                what you want to solve. Then, choose the data file on which you
                want the analysis to be done. All we need: your data has to be
                tabular (think Excel), and the data should contain answers to
                questions in Step 2.
              </p>
            </div>
            <div
              className="lg:w-1/3 px-8 py-8 mb-8 bg-white"
              data-aos="zoom-in"
              data-aos-delay={350}
            >
              <img className="w-1/2 mx-auto mb-8" src="/questions.svg" alt="" />
              <h3 className="text-2xl mb-4 h3">
                <span className="inline-flex items-center justify-center h-12 w-12 mr-2 border rounded-full">
                  2
                </span>
                <span>Help us by answering a few questions</span>
              </h3>
              <p className="text-gray-500">
                The machine asks you a few simple questions based on the problem
                you chose. Dont worry: we try to make the machine ask as few and
                as simple questions as possible.
              </p>
            </div>
            <div
              className="lg:w-1/3 px-8 py-8 mb-8 bg-white"
              data-aos="zoom-in"
              data-aos-delay={650}
            >
              <img className="w-1/2 mx-auto mb-8" src="/results.svg" alt="" />
              <h3 className="text-2xl mb-4 h3">
                <span className="inline-flex items-center justify-center h-12 w-12 mr-2 border rounded-full">
                  3
                </span>
                <span>Have a cup of coffee. And then see the results!</span>
              </h3>
              <p className="text-gray-500">
                And that's it! The machine automatically builds and tests 1000s
                of hypotheses, filters the most powerful, and then builds
                predictive models using our curated algorithms based on the
                problem you chose. Daisho also automatically builds and surfaces
                appropriate insights which you can use for business actions!
              </p>
            </div>
          </div>
          <div
            className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
            data-aos="zoom-y-out"
          >
            <button
              className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:mr-4 mb-4 sm:mb-0 px-5 py-3 rounded"
              onClick={() => setShowDemo(true)}
            >
              See Demo
            </button>
            <CTAButton />
          </div>
          {/* Modal */}
          <Modal
            id="daisho-demo"
            show={showDemo}
            handleClose={() => setShowDemo(false)}
          >
            <div className="relative pb-9/16">
              <iframe
                width="560"
                height="315"
                className="absolute w-full h-full"
                src="https://www.youtube.com/embed/8GN-Z5HSy1Y?rel=0"
                title="Video"
                allowFullScreen
              ></iframe>
            </div>
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
