import { useEffect } from "react";
import Transition from "./Transition";

export interface Props {
  children: React.ReactChildren | React.ReactChild;
  id: string;
  ariaLabel?: string;
  show: boolean;
  handleClose: () => any;
}

function Modal({ children, id, ariaLabel, show, handleClose }: Props) {
  // close the modal if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode === 27) {
        handleClose();
      }
    };
    document.addEventListener("keydown", keyHandler);

    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 z-50 bg-gray-600 bg-opacity-75 transition-opacity blur"
        show={show}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />

      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby={ariaLabel || id}
        show={show}
        enter="transition ease-out duration-200"
        enterStart="opacity-0 scale-95"
        enterEnd="opacity-100 scale-100"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100 scale-100"
        leaveEnd="opacity-0 scale-95"
        onClick={(e: React.MouseEvent) => {
          if (e.target === e.currentTarget) {
            handleClose();
          }
        }}
      >
        <div className="bg-white overflow-auto max-w-6xl w-full max-h-full m-4 p-4">
          {children}
        </div>
      </Transition>
    </>
  );
}

export default Modal;
