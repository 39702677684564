import clsx from "clsx";
import Link from "next/link";

import { useGetLoginStatus } from "lib/auth";

interface Props {
  color?: "primary" | "secondary";
}

const CTAButton = ({ color = "primary" }: Props) => {
  const loggedIn = useGetLoginStatus();

  return loggedIn ? (
    <a
      className={clsx(
        "btn w-full mb-4 sm:w-auto sm:mb-0 px-5 py-3 rounded",
        color === "secondary"
          ? "text-white bg-secondary-main hover:bg-secondary-dark"
          : "text-white bg-brand-600 hover:bg-brand-900"
      )}
      href={process.env.NEXT_PUBLIC_DAISHO_APP_LINK}
    >
      Start Analysis
    </a>
  ) : (
    <Link href="/signup?action=trial" passHref>
      <a className="btn text-white bg-brand-600 hover:bg-brand-900 w-full mb-4 sm:w-auto sm:mb-0 px-5 py-3 rounded">
        Start Free Trial
      </a>
    </Link>
  );
};

export default CTAButton;
