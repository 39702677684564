export interface Testimonial {
  image?: React.ReactNode;
  text: React.ReactNode;
  name?: string;
  designation?: string;
}

const listing: Testimonial[] = [
  {
    image: <img className="mx-auto mb-8" src="/completed.svg" alt="" />,
    text: (
      <>
        Two things I love about Daisho: First, the accuracy and the impact. The
        models added $4mn to our bottom-line. Second, the time and effort
        reduction: Our data scientists used to take 2-3 months to build ONE
        model. With Daisho, we were able to roll out 40 models in just one
        month. And we were able to accomplish that with a team of just 2 junior
        data scientists.
      </>
    ),
    designation: "Head of Data Science at a mid-size Corp",
  },
  {
    image: <img className="mx-auto mb-8" src="/haystack.svg" alt="" />,
    text: (
      <>
        We were struggling with a 600-page dashboard. Using Daisho, We were able
        to automatically figure out what’s important and also at a very granular
        level.
      </>
    ),
    designation: "Consumer Insights VP at India's largest CPG company",
  },
  {
    image: <img className="mx-auto mb-8" src="/stepping-up.svg" alt="" />,
    text: (
      <>
        The best our internal teams and consultants could do was a 75% accuracy.
        Daisho was able to deliver 93% accuracy, and also much deeper insights
        for problem diagnosis.
      </>
    ),
    designation: "Network Ops Director at a Telecom Company",
  },
  {
    image: <img className="mx-auto mb-8" src="/speed.svg" alt="" />,
    text: (
      <>
        Our consultant took 6 months to build a good model. Daisho got to an
        equivalent model in ONE DAY.
      </>
    ),
    designation: "Data Science Head at a CPG MNC",
  },
];

export default listing;
