import Head from "next/head";
import { NextSeo } from "next-seo";

import HeroHome from "elements/Hero";
import SuperPowers from "elements/Super-Powers-v2";
import HowItWorks from "elements/How-It-Works";
import UseCases from "elements/Use-Cases";
import Testimonials from "elements/Testimonials";
import CTA from "elements/CTA";

export default function Home() {
  return (
    <div>
      <Head>
        <title>Daisho | Become a Data Science SuperHero</title>
        <meta
          name="description"
          content="Daisho is No-Code Data Science for Business Users and Problems."
        />
        <link rel="icon" href="/daisho-favicon.png" />
      </Head>
      <NextSeo
        title="Daisho | Become a Data Science SuperHero"
        description="Daisho is No-Code Data Science for Business Users and Problems."
        canonical="https://www.daisho.ml"
      />
      <HeroHome />
      <SuperPowers />
      <HowItWorks />
      <UseCases />
      <Testimonials />
      <CTA />
    </div>
  );
}
