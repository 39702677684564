import React from "react";
import Link from "next/link";

import { useGetLoginStatus } from "lib/auth";

function CTA() {
  const loggedIn = useGetLoginStatus();

  if (loggedIn) {
    return null;
  }
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          {/* CTA box */}
          <div
            className="relative bg-blue-600 rounded py-10 px-8 md:px-12 overflow-hidden"
            data-aos="zoom-y-out"
          >
            <div className="relative flex flex-col lg:flex-row justify-between items-center">
              {/* CTA content */}
              <div className="text-center lg:text-left lg:max-w-xl">
                <h3 className="h3 text-white mb-2">Ready to get started?</h3>
                <p className="text-gray-300 lg:text-left lg:max-w-xl">
                  Try before you buy. 15 days free trial. No credit card
                  required.
                </p>
              </div>
              <div>
                <Link href="/signup" passHref>
                  <a className="btn text-xl bg-blue-100 hover:bg-blue-900 hover:text-white w-full mb-4 sm:w-auto sm:mb-0 px-5 py-3 rounded shadow">
                    Start Free Trial NOW
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTA;
