import { useState } from "react";

import CTAButton from "elements/CTA-Button";
import Illustration from "./Illustration";

import VideoModal from "./Video-Modal";

function HeroHome() {
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section className="relative">
      {/* Illustration behind hero content */}
      <Illustration />

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20 px-2">
          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h1
              className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
              data-aos="zoom-y-out"
            >
              Become a Data Science{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-800 to-green-400">
                SUPERHERO
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <div
                className="text-xl text-gray-600 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                <p>
                  Daisho is No-Code Data Science for Business Users and
                  Problems.
                </p>
                <p>
                  Select the problem you want to solve and point at the data:
                  Daisho does the rest.
                </p>
                <p>
                  Daisho automatically does the math, so you can focus on
                  BUSINESS INSIGHTS.
                </p>
              </div>
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <CTAButton />
                <a
                  className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4 px-5 py-3 rounded"
                  href="#super-powers"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>

          {/* Hero image */}
          <link
            rel="preload"
            href={"https://i.ytimg.com/vi/_oTRDDuLdKg/maxresdefault.jpg"}
            as="image"
          />
          <div>
            <div
              className="relative flex justify-center mb-8"
              data-aos="zoom-y-out"
              data-aos-delay="450"
            >
              <div className="flex flex-col justify-center">
                <img
                  className="mx-auto cursor-pointer"
                  src="https://i.ytimg.com/vi/_oTRDDuLdKg/maxresdefault.jpg"
                  alt="See Video"
                  width="768"
                  height="432"
                  onClick={(e) => {
                    e.preventDefault();
                    setVideoModalOpen(true);
                  }}
                />
              </div>
              <button
                className="absolute top-full flex items-center transform -translate-y-1/2 bg-white rounded-full font-medium group p-4 shadow-lg"
                onClick={(e) => {
                  e.preventDefault();
                  setVideoModalOpen(true);
                }}
                aria-controls="modal"
              >
                <svg
                  className="w-6 h-6 fill-current text-gray-400 group-hover:text-blue-600 flex-shrink-0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                  <path d="M10 17l6-5-6-5z" />
                </svg>
                <span className="ml-3">Watch the full video (90 sec)</span>
              </button>
            </div>

            {/* Modal */}
            <VideoModal
              show={videoModalOpen}
              handleClose={() => setVideoModalOpen(false)}
            />
            {/* {videoModalOpen && (
              <VideoModal
                show={videoModalOpen}
                handleClose={() => setVideoModalOpen(false)}
              />
            )} */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
