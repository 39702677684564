import { useEffect, useState } from "react";

import Modal from "components/Modal";

const VideoModal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => any;
}) => {
  const [preconnected, setPreconnected] = useState(false);
  const [iframe, setIframe] = useState(false);

  useEffect(() => {
    if (!iframe) {
      setIframe(true);
    }
    if (!preconnected) {
      setPreconnected(true);
    }
  }, [iframe, preconnected]);

  const ytUrl = "https://www.youtube-nocookie.com/embed/_oTRDDuLdKg?rel=0";

  return (
    <>
      {preconnected && (
        <>
          <link rel="preconnect" href={ytUrl} />
          <link rel="preconnect" href="https://www.google.com" />
        </>
      )}
      <Modal id="daisho-video-modal" show={show} handleClose={handleClose}>
        <div className="relative pb-9/16">
          {show && iframe && (
            <iframe
              className="absolute w-full h-full"
              src={ytUrl}
              title="Video"
              allowFullScreen
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          )}
        </div>
      </Modal>
    </>
  );
};

export default VideoModal;
