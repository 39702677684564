import Image from "next/image";

import { Feature } from "./types";

import { Carousel } from "react-responsive-carousel";

const features: Feature[] = [
  {
    heading: "No Code. No Math",
    text: (
      <div className="text-center">
        <p>
          Daisho's pre-built workflows solve most common business problems. Just
          select the workflow, and point at the data. Daisho does the rest.
        </p>
        <p>
          Based on the workflow, Daisho's builds and tests 1000s of hypotheses,
          and highlights the most powerful ones.
        </p>
        <p>
          Daisho also has tons of workflows for free-flow analysis of your data.
        </p>
      </div>
    ),
    content: (
      <Carousel showThumbs={false} showStatus={false} autoPlay infiniteLoop>
        <div className="bg-white h-full">
          <p className="pt-4 text-base font-bold">Prebuilt Workflows</p>
          <Image
            src="/flows.png"
            width={640}
            height={400}
            alt="Recipes"
            className="md:max-w-2xl mx-auto rounded object-contain pb-4"
          />
        </div>
        <div className="bg-white h-full">
          <p className="pt-4 pb-2 text-base font-bold">
            Automatic Hypothesis Generation and Filtering
          </p>
          <Image
            src="/Secret-Sauce-Final.svg"
            alt={"Hypothesis Discovery"}
            width={640}
            height={400}
            className="md:max-w-2xl mx-auto rounded object-contain pb-4"
          />
        </div>
        <div className="bg-white h-full">
          <p className="pt-4 text-base font-bold">
            Free Flow Analysis on ANY Data
          </p>
          <Image
            src="/free-flow.png"
            alt={"Free Flow Analysis"}
            width={640}
            height={400}
            className="md:max-w-2xl mx-auto rounded object-contain pb-4"
          />
        </div>
      </Carousel>
    ),
  },
  {
    heading: "Uncover the Unknown",
    text: (
      <>
        Daisho has pre-built <span className="italic font-bold">recipes</span>{" "}
        for most common business problems: driver-analysis, time-series
        analysis, forecasting, churn, predictive maintenance, etc. Supports
        one-off analysis as well as hourly/daily/weekly predictions and actions.
      </>
    ),

    content: (
      <Carousel
        showThumbs={false}
        showStatus={false}
        autoPlay
        infiniteLoop
        stopOnHover
        useKeyboardArrows
      >
        <div className="bg-white h-full">
          <p className="pt-4 text-base font-bold">
            Bias-Free Analysis - leading to the{" "}
            <span className="italic underline">Orange Box</span>
          </p>
          <Image
            src="/four-boxes.png"
            width={640}
            height={400}
            alt="Bias-Free Analysis"
            className="md:max-w-2xl mx-auto rounded object-contain pb-4"
          />
        </div>
        <div className="bg-white h-full">
          <p className="text-base font-bold text-center pt-4">
            Business understandable Hypothesis
          </p>
          <p className="text-sm text-center">
            Historical trends - short/long term, Familiarity Indices, Geographic
            Signals, and Many More...
          </p>
          <Image
            src="/signal-factory-hypotheses.png"
            alt="Signal Factory"
            className="md:max-w-2xl mx-auto rounded object-contain pt-4"
            width={640}
            height={400}
          />
        </div>
      </Carousel>
    ),
  },
  {
    heading: "Actionable Insights",
    text: (
      <div className="text-center">
        <p>
          Daisho generates a bunch of insights AUTOMATICALLY based on the
          problem you are trying to understand.
        </p>
        <p>
          Not only that, Daisho can also build predictions for you. And you can
          setup auto-learn as well - as new data keeps rolling in, Daisho can
          automatically update itself.
        </p>
      </div>
    ),

    content: (
      <Carousel showThumbs={false} showStatus={false} autoPlay infiniteLoop>
        <div className="bg-white h-full">
          <p className="pt-4 text-base font-bold">Automatic Insights</p>
          <Image
            src="/recipe-insights.png"
            width={640}
            height={400}
            className="md:max-w-2xl mx-auto rounded object-contain pt-4"
            alt="Recipe Insights"
          />
        </div>
        <div className="bg-white h-full">
          <p className="pt-4 text-base font-bold">
            Get predictions on a single data point. Or on a File (batch)
          </p>
          <Image
            src="/predictions.png"
            width={640}
            height={400}
            className="md:max-w-2xl mx-auto rounded object-contain pt-4"
            alt="Predictions"
          />
        </div>
        <div className="bg-white h-full">
          <p className="pt-4 text-base font-bold">See Feature Impacts</p>
          <Image
            src="/feature-impacts.png"
            width={640}
            height={400}
            alt="Feature Impacts"
            className="md:max-w-2xl mx-auto rounded object-contain pt-4"
          />
        </div>
        <div className="bg-white h-full">
          <p className="pt-4 text-base font-bold">Build Simulations</p>
          <Image
            src="/simulations.png"
            width={640}
            height={400}
            alt="Simulations"
            className="md:max-w-2xl mx-auto rounded object-contain pt-4"
          />
        </div>
        <div className="bg-white h-full">
          <p className="pt-4 text-base font-bold">And many many more...</p>
          <Image
            src="/cluster-profiles.png"
            width={640}
            height={400}
            alt="Profiles"
            className="md:max-w-2xl mx-auto rounded object-contain pt-4"
          />
        </div>
      </Carousel>
    ),
  },
];

export default features;
